/**
 *    SPDX-License-Identifier: Apache-2.0
 */
import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import { Observer } from "./Observer";
import Modal from "@mui/material/Modal";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";

import {
  Button,
  TextField,
  Select,
  MenuItem,
  Box,
  FormControl,
  InputLabel,

  //Alert
} from "@material-ui/core";
import { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import ViewTable from "../Styled/ViewTable";
import FontAwesome from "react-fontawesome";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Table, Card, CardBody, CardTitle } from "reactstrap";
import { transactionType } from "../types";

/* eslint-disable */
const readTheme = {
  base00: "#f3f3f3",
  base01: "#2e2f30",
  base02: "#515253",
  base03: "#737475",
  base04: "#959697",
  base05: "#b7b8b9",
  base06: "#dadbdc",
  base07: "#fcfdfe",
  base08: "#e31a1c",
  base09: "#e6550d",
  base0A: "#dca060",
  base0B: "#31a354",
  base0C: "#80b1d3",
  base0D: "#3182bd",
  base0E: "#756bb1",
  base0F: "#b15928",
};
const writeTheme = {
  base00: "#ffffff",
  base01: "#2e2f30",
  base02: "#515253",
  base03: "#737475",
  base04: "#959697",
  base05: "#b7b8b9",
  base06: "#dadbdc",
  base07: "#fcfdfe",
  base08: "#e31a1c",
  base09: "#e6550d",
  base0A: "#dca060",
  base0B: "#31a354",
  base0C: "#80b1d3",
  base0D: "#3182bd",
  base0E: "#756bb1",
  base0F: "#b15928",
};
const dark = false;
/* eslint-enable */
const styles = (theme) => ({
  listIcon: {
    color: "#ffffff",
    marginRight: 20,
  },
  JSONtree: {
    "& ul": {
      backgroundColor: "transparent !important",
      color: "#fff",
    },
  },
  readset_null: {
    display: "none",
  },
  fullwidth: {
    width: "100%",
    marginTop: 105,
    backgroundColor: "#f0f5f9",
  },
  display: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "80%",
    marginTop: 50,
    backgroundColor: "transparent",
  },
  displayImg: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    height: "100px",
    width: "100px",
    marginTop: 50,
    backgroundColor: "transparent",
  },
  displayModal: {
    display: "grid",
    marginLeft: "auto",
    marginRight: "auto",
    height: "600px",
    width: "400px",
    marginTop: 50,
    backgroundColor: "white",
    justifyContent: "center",
  },
  alert: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "80%",
    marginTop: 50,
    backgroundColor: "transparent",
  },
  card: {
    color: "#ffffff",
    backgroundColor: "##ffeeff",
  },
  title: {
    textAlign: "center",
    backgroundColor: dark ? "#242036" : "#004d6b",
    color: "#ffffff",
    font: "bold 30px",
    fontWeight: 400,
    letterSpacing: 3,
    paddingTop: "1%",
    margin: 0,
  },
  dialog: {
    borderStyle: "solid",
    borderColor: "#d8d6d6",
    borderWidth: 2,
    filter: "drop-shadow(0 0 0.75rem #d8d6d6)",
    backgroundColor: dark ? "#453e68" : undefined,
    color: dark ? "#ffffff" : undefined,
  },
  formControlRow: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    marginBottom: "20px",
  },

  body: {
    backgroundColor: dark ? "#ffffff" : undefined,
    color: dark ? "#ffffff" : undefined,
    "& > h1": {
      fontSize: "25pt",
    },
  },
  copy: {
    display: "none",
    color: dark ? "#ffffff" : undefined,
  },
  copied: {
    display: "none",
    color: dark ? "#ffffff" : undefined,
  },
  copyBtn: {
    float: "right",
    borderColor: "transparent",
    color: dark ? "#ffffff" : "##ffffff",
    backgroundColor: "transparent",
    cursor: "pointer",
    textShadow: "1px 1px 2px rgba(129, 129, 129, 0.753)",
    "&:hover": {
      color: dark ? "#ffffff" : "#2153f8",
    },
    "&:focus": {
      outline: "none",
    },
    "&:hover $copy": {
      display: "block",
      position: "absolute",
      padding: 0,
      backgroundColor: "transparent",
      fontSize: 10,
      marginTop: -10,
    },
    "&:active $copy": {
      display: "none",
    },
    "&:active $copied": {
      display: "block",
      position: "absolute",
      padding: 0,
      backgroundColor: "transparent",
      marginTop: -30,
    },
  },
  closeBtn: {
    float: "right",
    borderColor: "transparent",
    color: "#8b0000",
    backgroundColor: "transparent",
    margin: "-12px 10px 3px 0px",
    fontSize: 25,
    height: 30,
    cursor: "pointer",
    filter: "drop-shadow(0 0 0.75rem #D8D6D6)",
    "&:focus": {
      outline: "none",
      color: "#c00404",
    },
  },
});
const apiUrl = window.REACT_APP_API_URL;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "white",
  boxShadow: "24",
  padding: "16px",
};

const reads = {
  color: "#2AA233",
};
const writes = {
  color: "#DD8016",
};

export class InvokeView extends Component {
  inputObj = {};

  runChaincodeFunction = async (e) => {
    e.preventDefault();
    this.setState({ openLoaderModal: true });

    const {
      functionName,
      inputObj,
      selectedUser,
      selectedChaincode,
      orgName,
      channelName,
    } = this.state;

    let requestBody = {
      functionName: functionName,
      arguments: inputObj,
      userId: selectedUser,
      chaincodeName: selectedChaincode,
      orgName: orgName,
      channelName: channelName,
    };

    fetch(`${apiUrl}/callChaincodeFunction`, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          // If the response is not ok, parse the JSON error object and reject it
          return response.json().then((errorData) => Promise.reject(errorData));
        }
        // If the response is ok, parse the JSON success object
        return response.json();
      })
      .then((data) => {
        this.setState({
          openLoaderModal: true,
          showSuccessMessage: true,
          showErrorMessage: false,
          successMessage: data.message,
          resultData: data.result,
          executedFunctionName: this.functionName,
        });
      })
      .catch((error) => {
        this.setState({
          openLoaderModal: true,
          showSuccessMessage: false,
          showErrorMessage: true,
          errorMessage:
            error.message || error.error || "An unknown error occurred",
          resultData: null,
        });
      });
  };

  fetchNetworkInputs = async () => {
    this.setState({
      openLoaderModal: true,
      showSuccessMessage: false,
      showErrorMessage: false,
    }); // Show loader if desired

    fetch(`${apiUrl}/callInput`) // Ensure the method is GET if you're not sending data
      .then((response) => {
        if (!response.ok) {
          // If the response is not ok, parse the JSON error object and reject it
          return response.json().then((errorData) => Promise.reject(errorData));
        }
        // If the response is ok, parse the JSON success object
        return response.json();
      })
      .then((data) => {
        // Assuming 'data' is the JSON object you showed
        this.setState({
          showSuccessMessage: true,
          successMessage: "Fetched network inputs successfully.",
          resultData: JSON.stringify(data, null, 2), // Format the JSON data nicely
        });
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        this.setState({
          showErrorMessage: true,
          errorMessage: error.message || "An unknown error occurred",
        });
      })
      .finally(() => {
        this.setState({ openLoaderModal: false }); // Hide loader
      });
  };

  renderFunctions = () => {
    return this.state.functions2Render.map((func) => (
      <MenuItem key={func.name} value={func.name}>
        {func.name}
      </MenuItem>
    ));
  };
  renderInputs = () => {
    const currentFunction = this.state.functions2Render.find(
      (func) => func.name === this.state.functionName
    );

    if (!currentFunction) return null;

    return currentFunction.inputs.map((input, index) => (
      <TextField
        key={`${this.state.functionName}-${input.name}-${index}`}
        label={input.name}
        variant="outlined"
        value={this.state.inputValues[input.name] || ""}
        onChange={(e) => this.handleInputChange(e, input.name)}
        style={{ width: "100%", margin: "10px 0" }}
      />
    ));
  };

  handleInputChange = (event, inputName) => {
    const value = event.target.value;

    this.setState((prevState) => ({
      inputValues: {
        ...prevState.inputValues,
        [inputName]: value,
      },
    }));
  };

  functionParameters = {
    mspOrg: "",
    password: "",
    orgUserId: "",
    chaincodeName: "",
    functionName: "",
    parameters: [],
  };

  runAddNodeScript = async () => {
    const { newNodeOrgName, newNodeName, newNodePort, newCcPort } = this.state;

    // Concatenate the input values into a comma-separated string
    const answersList = [newNodeOrgName, newNodeName, newNodePort, newCcPort];
    const concatenatedAnswers = answersList.join(",");

    // Construct the request body using the concatenated string
    const requestBody = {
      filename: "network.sh",
      mode: "newNodeServer",
      answers: concatenatedAnswers,
    };

    try {
      const response = await fetch(`${apiUrl}/run-network-script`, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        // If the response is not ok, throw an error
        throw new Error("Network response was not ok.");
      }

      // If the response is ok, parse the JSON data
      const data = await response.json();

      // Here you can handle the response data
      console.log("Success:", data);

      // Optionally, you could update state to reflect the success or any other actions
      // this.setState({
      //   addNodeSuccessMessage: 'Node added successfully!',
      //   addNodeResult: data.result,
      // });
    } catch (error) {
      // Handle errors, e.g., by setting an error state or displaying a message
      console.error("Error:", error);
      // this.setState({
      //   addNodeErrorMessage: error.message || 'An error occurred while trying to add the node.',
      // });
    }
  };

  checked = false;
  setChannelAnswers = (value) => {
    this.channelAnswers = value;
  };
  setFunctionParameters = (value, name) => {
    let newInputObj = { ...this.state.inputObj };
    newInputObj[name] = value;

    this.setState({
      inputObj: newInputObj,
    });
  };

  setNodeAnswers = (value) => {
    this.nodeAnswers = value;
  };
  createUser = async () => {
    const requestBody = {
      userId: this.state.selectedUser,
      orgName: "client1",
      caName: "Ca1",
    };
    fetch(`${apiUrl}/create-user`, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
        } else {
        }
      })
      .catch((error) => {
        // Handle network errors
        console.error("Network error:", error);
        // Update UI or state as needed
      });
  };
  fetchFunctionNames = async () => {
    const requestBody = {
      chaincodeName: this.state.selectedChaincode,
    };

    try {
      const response = await fetch(`${apiUrl}/getJson`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) throw new Error("Failed to fetch chaincode functions");

      const data = await response.json();

      // Parse the invokeFunctions and queryFunctions if they exist in the response

      // Update the component's state with the fetched functions
      this.setState({
        fetchedFunctions: data,
        functions2Render: data.invokeFunctions,
      });
    } catch (error) {
      console.error("Failed to fetch function names:", error);
      // Optionally, handle errors, e.g., by setting an error state or displaying a message
    }
  };
  runAddChannelScript = async () => {
    const {
      consortiumName,
      channelName,
      channelCreator,
      ordererOrg,
      ordererNode,
      numMembers,
      selectedOrg,
      selectedNode,
    } = this.state;

    const answersList = [
      consortiumName,
      channelName,
      channelCreator,
      ordererOrg,
      ordererNode,
      numMembers,
      selectedOrg,
      selectedNode,
    ];

    const concatenatedAnswers = answersList.join(",");

    const requestBody = {
      filename: "network.sh",
      mode: "newChannelServer",
      answers: concatenatedAnswers,
    };

    try {
      const response = await fetch(`${apiUrl}/run-network-script`, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const data = await response.json();
      console.log("Success:", data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  invokeSetChainCodeParameters = async () => {
    fetch(`${apiUrl}/update-chaincode-params`, {
      method: "POST",
      body: JSON.stringify(this.reqBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handleClose = () => {
    this.setState({
      openLoaderModal: false,
      showSuccessMessage: false,
      showErrorMessage: false,
      successMessage: "",
      errorMessage: "",
      resultData: null,
    });
  };

  channelAnswers = "";
  nodeAnswers = "";
  state = {
    openLoaderModal: false,
    openModal: false,
    initRequired: false,
    functionName: "none",
    showSuccessMessage: false,
    selectedUser: "",
    caName: "",
    selectedChaincode: "",
    executedFunctionName: "",
    successMessage: "",
    showErrorMessage: false,
    functions2Render: [],
    errorMessage: "",
    resultData: null,
    inputValues: {},
    httpMethod: "POST",
    fetchedFunctions: {},
    orgName: "",
    channelName: "",
    orgType: "",
    orgName: "",
    couchDbPort: "",
    caName: "",
    caPort: "",
    nodeNames: "",
    nodePort1: "",
    nodeChaincodePort1: "",
    consortiumName: "",
    channelName: "",
    channelCreator: "",
    ordererOrg: "",
    ordererNode: "",
    numMembers: "",
    selectedOrg: "",
    selectedNode: "",
    joinChannelName: "",
    joinOrgName: "",
    joinPeer1: "",
    joinPeer2: "",
    newNodeOrgName: "",
    newNodeName: "",
    newNodePort: "",
    newCcPort: "",
    openAddOrgModal: false,
    openJoinChannelModal: false,
    openAddNodeModal: false,
    openAddChannelModal: false,
  };
  reqBody = {
    CC_NAME: "",
    CC_VERSION: "",
    CC_SRC_PATH: "",
    INIT_FUNCTION: "",
    INIT_REQUIRED: false,
  };

  handleUserChange = (event) => {
    this.setState({ selectedUser: event.target.value });
  };

  handleChaincodeChange = (event) => {
    this.setState({ selectedChaincode: event.target.value });
  };

  handleHttpMethodChange = (event) => {
    const newMethod = event.target.value;
    let f2r =
      newMethod == "POST"
        ? this.state.fetchedFunctions.invokeFunctions
        : this.state.fetchedFunctions.queryFunctions;
    this.setState({
      httpMethod: newMethod,
      functions2Render: f2r,
    });
  };

  setNameValue = (value) => {
    this.reqBody.CC_NAME = value;
  };

  setVersionValue = (value) => {
    this.reqBody.CC_VERSION = value;
  };

  setPathValue = (value) => {
    this.reqBody.CC_SRC_PATH = value;
  };

  setInitFuncValue = (value) => {
    this.reqBody.INIT_FUNCTION = value;
  };
  setInitRequiredValue = (e) => {
    if (this.reqBody.INIT_REQUIRED === true) {
      this.reqBody.INIT_REQUIRED = false;
    } else {
      this.reqBody.INIT_REQUIRED = true;
    }
    this.setState({ initRequired: this.reqBody.INIT_REQUIRED });
  };

  onClickButton = (e) => {
    e.preventDefault();
    this.setState({ openModal: true });
  };
  onCloseLoaderModal = () => {
    this.setState({ openLoaderModal: false });
  };
  onCloseModal = async () => {
    await this.invokeSetChainCodeParameters();

    this.setState({ openModal: false });
  };
  functionName = "";
  handleFuncNameChange = (event) => {
    this.setState({ functionName: event.target.value });
    this.setState({ inputObj: {} });
  };
  networkMode = "";
  setNetworkMode = (event) => {
    this.networkMode = event.target.value;
  };
  runNetworkMode = async () => {
    let reqBody = {
      mode: this.networkMode,
      filename: "network.sh",
    };

    fetch(`${apiUrl}/run-network-script`, {
      method: "POST",
      body: JSON.stringify(reqBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  mainChannelName = "";
  setMainChannelName = (event) => {
    this.mainChannelName = event.target.value;
  };

  runChaicodeMain = async () => {
    let reqBody = {
      filename: "network.sh",
      channelName: this.mainChannelName,
    };

    fetch(`${apiUrl}/run-chaincode-main`, {
      method: "POST",
      body: JSON.stringify(reqBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  answers = "";
  setAnswers = (event) => {
    this.answers = event.target.value;
  };
  joinChannelAnswers = "";
  setJoinChannelAnswers = (event) => {
    this.joinChannelAnswers = event.target.value;
  };
  functionToExecute = "";
  setFunctionToExecute = (event) => {
    const newFunctionName = event.target.value;

    this.setState({
      functionName: newFunctionName,
      functionToExecute: newFunctionName,
      inputValues: {},
      inputObj: {},
    });
  };

  funcInputs = [];
  setFuncInputs = (name, event) => {
    this.funcInputs.push({ inputName: name, value: event.target.value });
  };
  renderedInputs = [];
  runJoinChannelScript = async () => {
    const { joinChannelName, joinOrgName, joinPeer1, joinPeer2 } = this.state;

    // Concatenate the input values into a single string separated by commas
    const answersList = [joinChannelName, joinOrgName, joinPeer1, joinPeer2];
    const concatenatedAnswers = answersList.join(",");

    // Construct the request body with the concatenated answers
    const requestBody = {
      filename: "network.sh",
      mode: "join",
      answers: concatenatedAnswers,
    };

    try {
      const response = await fetch(`${apiUrl}/run-network-script`, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        // If the response is not ok, throw an error
        throw new Error("Network response was not ok.");
      }

      // If the response is ok, parse the JSON data
      const data = await response.json();

      // Here you can handle the response data
      console.log("Success:", data);

      // Optionally, you could update state to reflect the success or any other actions
      // this.setState({
      //   joinSuccessMessage: 'Channel joined successfully!',
      //   joinChannelResult: data.result,
      // });
    } catch (error) {
      // Handle errors, e.g., by setting an error state or displaying a message
      console.error("Error:", error);
      // this.setState({
      //   joinErrorMessage: error.message || 'An error occurred while trying to join the channel.',
      // });
    }
  };

  runNetworkScript = async () => {
    let answersList = this.answers.split(",");

    let requestBody = {
      filename: "network.sh",
      answers: answersList,
      mode: "join",
    };
    console.log(requestBody);
    fetch(`${apiUrl}/run-network-script`, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response from the API
        // Replace with your desired logic
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  newOrgAnswers = "";
  setNewOrgAnswers = (event) => {
    this.newOrgAnswers = event.target.value;
  };
  setOrgType = (event) => {
    this.setState({ orgType: event.target.value });
  };

  setOrgName = (event) => {
    this.setState({ orgName: event.target.value });
  };

  setCouchDbPort = (event) => {
    this.setState({ couchDbPort: event.target.value });
  };

  setCaName = (event) => {
    this.setState({ caName: event.target.value });
  };

  setCaPort = (event) => {
    this.setState({ caPort: event.target.value });
  };

  setNodeNames = (event) => {
    this.setState({ nodeNames: event.target.value });
  };

  setNodePort1 = (event) => {
    this.setState({ nodePort1: event.target.value });
  };

  handleOpenAddOrgModal = () => {
    this.setState({ openAddOrgModal: true });
  };

  handleCloseAddOrgModal = () => {
    this.setState({ openAddOrgModal: false });
  };

  handleOpenAddChannelModal = () => {
    this.setState({ openAddChannelModal: true });
  };

  handleCloseAddChannelModal = () => {
    this.setState({ openAddChannelModal: false });
  };
  handleOpenJoinChannelModal = () => {
    this.setState({ openJoinChannelModal: true });
  };

  handleCloseJoinChannelModal = () => {
    this.setState({ openJoinChannelModal: false });
  };
  handleOpenAddNodeModal = () => {
    this.setState({ openAddNodeModal: true });
  };

  handleCloseAddNodeModal = () => {
    this.setState({ openAddNodeModal: false });
  };

  setNodeChaincodePort1 = (event) => {
    this.setState({ nodeChaincodePort1: event.target.value });
  };
  setConsortiumName = (event) => {
    this.setState({ consortiumName: event.target.value });
  };

  setChannelName = (event) => {
    this.setState({ channelName: event.target.value });
  };

  setChannelCreator = (event) => {
    this.setState({ channelCreator: event.target.value });
  };

  setOrdererOrg = (event) => {
    this.setState({ ordererOrg: event.target.value });
  };

  setOrdererNode = (event) => {
    this.setState({ ordererNode: event.target.value });
  };

  setNumMembers = (event) => {
    this.setState({ numMembers: event.target.value });
  };

  setSelectedOrg = (event) => {
    this.setState({ selectedOrg: event.target.value });
  };

  setSelectedNode = (event) => {
    this.setState({ selectedNode: event.target.value });
  };
  setJoinChannelName = (event) => {
    this.setState({ joinChannelName: event.target.value });
  };

  setJoinOrgName = (event) => {
    this.setState({ joinOrgName: event.target.value });
  };

  setJoinPeer1 = (event) => {
    this.setState({ joinPeer1: event.target.value });
  };

  setJoinPeer2 = (event) => {
    this.setState({ joinPeer2: event.target.value });
  };
  setNewNodeOrgName = (event) => {
    this.setState({ newNodeOrgName: event.target.value });
  };

  setNewNodeName = (event) => {
    this.setState({ newNodeName: event.target.value });
  };

  setNewNodePort = (event) => {
    this.setState({ newNodePort: event.target.value });
  };

  setNewCcPort = (event) => {
    this.setState({ newCcPort: event.target.value });
  };

  runAddNewOrgScript = async () => {
    const {
      orgType,
      orgName,
      couchDbPort,
      caName,
      caPort,
      nodeNames,
      nodePort1,
      nodeChaincodePort1,
    } = this.state;

    const answersList = [
      orgType,
      orgName,
      couchDbPort,
      caName,
      caPort,
      nodeNames,
      nodePort1,
      nodeChaincodePort1,
    ];

    const concatenatedAnswers = answersList.join(",");

    const requestBody = {
      filename: "network.sh",
      mode: "newOrgServer",
      answers: concatenatedAnswers,
    };

    try {
      const response = await fetch(`${apiUrl}/run-network-script`, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const data = await response.json();

      console.log("Success:", data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  setFunctionPassword = (value) => {
    this.functionParameters.password = value;
  };
  setFunctionMspOrg = (value) => {
    this.functionParameters.mspOrg = value;
  };
  setFunctionOrgUserId = (value) => {
    this.functionParameters.orgUserId = value;
  };
  setFunctionChaincodeName = (value) => {
    this.functionParameters.chaincodeName = value;
  };

  render() {
    const { transaction, classes } = this.props;
    const func = "";
    return (
      <div className={classes.fullwidth}>
        {this.state.showErrorMessage && (
          <Alert severity="error" className={classes.alert}>
            {this.state.errorMessage}
          </Alert>
        )}

        {this.state.showSuccessMessage && (
          <div>
            <Alert severity="success" className={classes.alert}>
              {this.state.successMessage}
            </Alert>
            <Alert severity="info" className={classes.alert}>
              <pre>{this.state.resultData}</pre>
              <CopyToClipboard
                text={JSON.stringify(this.state.resultData, null, 2)}
                onCopy={() => this.setState({ copied: true })}
              >
                <Button>Copy to Clipboard</Button>
              </CopyToClipboard>
              {this.state.copied ? (
                <span style={{ color: "green" }}>Copied.</span>
              ) : null}
            </Alert>
          </div>
        )}
        <img
          className={classes.displayImg}
          src="https://www.astrakode.tech/wp-content/themes/astrakode/img/astra-logo.svg"
        />
        <h1 className={classes.display}>Run Chaincode Function</h1>
        <Alert className={classes.display} severity="info">
          Select Chaincode Function to Execute
        </Alert>
        <ViewTable>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "10px",
              }}
            >
              {/* User input */}
              <TextField
                label="User"
                variant="outlined"
                value={this.state.selectedUser}
                onChange={(event) =>
                  this.setState({ selectedUser: event.target.value })
                }
                style={{ flex: 1, margin: "10px", maxWidth: "200px" }} // Set a max width if needed
              />

              {/* Chaincode input */}
              <TextField
                label="Chaincode"
                variant="outlined"
                value={this.state.selectedChaincode}
                onChange={(event) =>
                  this.setState({ selectedChaincode: event.target.value })
                }
                style={{ flex: 1, margin: "10px", maxWidth: "200px" }} // Set a max width if needed
              />

              {/* Org Name input */}
              <TextField
                label="Org Name"
                variant="outlined"
                value={this.state.orgName}
                onChange={(event) =>
                  this.setState({ orgName: event.target.value })
                }
                style={{ flex: 1, margin: "10px", maxWidth: "200px" }} // Set a max width if needed
              />

              {/* Channel Name input */}
              <TextField
                label="Channel Name"
                variant="outlined"
                value={this.state.channelName}
                onChange={(event) =>
                  this.setState({ channelName: event.target.value })
                }
                style={{ flex: 1, margin: "10px", maxWidth: "200px" }} // Set a max width if needed
              />

              {/* Fetch Function button */}
              <Button
                variant="contained"
                onClick={this.fetchFunctionNames}
                style={{ margin: "10px" }}
              >
                Fetch Function
              </Button>

              {/* HTTP Method dropdown */}
              <FormControl
                style={{ flex: 1, margin: "10px", maxWidth: "200px" }}
              >
                <InputLabel id="http-method-select-label">
                  HTTP Method
                </InputLabel>
                <Select
                  labelId="http-method-select-label"
                  id="http-method-select"
                  value={this.state.httpMethod}
                  onChange={this.handleHttpMethodChange}
                  style={{ width: "100%" }}
                >
                  <MenuItem value="POST">INVOKE</MenuItem>
                  <MenuItem value="GET">QUERY</MenuItem>
                </Select>
              </FormControl>

              {/* Function dropdown */}
              <FormControl
                style={{ flex: 1, margin: "10px", maxWidth: "200px" }}
              >
                <InputLabel id="function-select-label">Function</InputLabel>
                <Select
                  labelId="function-select-label"
                  id="function-select"
                  value={this.state.functionName}
                  onChange={this.setFunctionToExecute}
                  style={{ width: "100%" }}
                >
                  {this.renderFunctions()}
                </Select>
              </FormControl>
            </div>
          </div>

          <Observer
            funcName={this.state.functionName}
            fetchedFunctions={this.state.functions2Render}
            setFunctionParameters={this.setFunctionParameters}
            inputObj={this.state.inputObj} // pass inputObj to Observer
          />

          <Table hover responsive>
            <tbody></tbody>
          </Table>
          <Button
            variant="contained"
            onClick={this.runChaincodeFunction}
            style={{
              top: "5px",
              bottom: "20px",
              left: "60px",
            }}
          >
            Execute Function
          </Button>
          <Modal
            open={this.state.openLoaderModal}
            onClose={this.onCloseLoaderModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className={classes.dialog}>
              <Card className={classes.card}>
                <CardTitle className={classes.title}>
                  Executing Function
                  <button
                    type="button"
                    onClick={this.handleClose}
                    className={classes.closeBtn}
                  >
                    <FontAwesome name="close" />
                  </button>
                </CardTitle>
                <CardBody className={classes.body}>
                  {this.state.showSuccessMessage && (
                    <Alert severity="success" className={classes.alert}>
                      {this.state.successMessage}
                    </Alert>
                  )}
                  {this.state.resultData && (
                    <Alert severity="info" className={classes.alert}>
                      <pre
                        style={{
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                        }}
                      >
                        {JSON.stringify(this.state.resultData, null, 2)}
                      </pre>
                      <CopyToClipboard
                        text={JSON.stringify(this.state.resultData, null, 2)}
                        onCopy={() => this.setState({ copied: true })}
                      >
                        <Button>Copy to Clipboard</Button>
                      </CopyToClipboard>
                      {this.state.copied ? (
                        <span style={{ color: "green" }}>Copied.</span>
                      ) : null}
                    </Alert>
                  )}
                  {this.state.showErrorMessage && (
                    <Alert severity="error" className={classes.alert}>
                      {this.state.errorMessage}
                    </Alert>
                  )}
                  {!this.state.showSuccessMessage &&
                    !this.state.showErrorMessage && (
                      <CircularProgress color="grey" />
                    )}
                  <Button
                    variant="contained"
                    style={{ marginTop: "20px" }}
                    onClick={this.onCloseLoaderModal}
                  >
                    Close
                  </Button>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </ViewTable>
      </div>
    );
  }
}

InvokeView.propTypes = {
  transaction: transactionType,
};

InvokeView.defaultProps = {
  transaction: null,
};

export default withStyles(styles)(InvokeView);
